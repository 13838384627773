<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>预约列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div>
        <!-- 表格 -->
        <el-table :data="YUList">
          <el-table-column label="患者姓名" prop="patientName"></el-table-column>
          <el-table-column label="手机号" prop="memberPhone"></el-table-column>
          <!-- <el-table-column label="年龄" prop="age"></el-table-column> -->
          <el-table-column label="预约时间">
            <template slot-scope="scope">
              <div>
                {{scope.row.orderTime+' '+scope.row.orderHour}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="预约医生" prop="doctorName"></el-table-column>
          <el-table-column label="医疗项目" prop="treatmentName">
            <template slot-scope="scope">
              <div>
                {{scope.row.treatmentName || '无'}}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" prop="content" width="200">
            <template slot-scope="scope">
              <el-button class="daochu_btn" type="success" @click="removeOut(scope.row)">叫号</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- 分页区域 -->
        <el-pagination style="margin: 15px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page" :page-sizes="[10,20,100]" :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        page: 1,
        limit: 10,
        YUList: [],
        total: 0,
      }
    },
    created() {
      this.getYUList()
    },
    methods: {
      // 叫号
      removeOut() {
        window.speechSynthesis.speak(new window.SpeechSynthesisUtterance(res.data.data))
      },
      // 获取预约列表
      getYUList() {
        this.$http.post(`order/selectYuYueList`, {
          size: this.limit,
          current: this.page,
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            for (let i = 0; i < res.data.data.list.length; i++) {
              if (res.data.data.list[i].gender === "1") {
                res.data.data.list[i].gender = "男";
              } else if (res.data.data.list[i].gender === "2") {
                res.data.data.list[i].gender = "女";
              }
            }
            this.YUList = res.data.data.list
          } else {

          }
        })
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize;
        this.getYUList()
      },
      handleCurrentChange(newPage) {
        this.page = newPage;
        this.getYUList()
      },
    }
  }
</script>

<style scoped>

</style>